import { Component, Input, OnInit } from "@angular/core";
import { transitionAnimation } from "../../utils/animations";

@Component({
  selector: "modal-table-in-payment",
  templateUrl: "./table-in-payment.component.html",
  styleUrls: ["./table-in-payment.component.scss"],
  animations: [transitionAnimation],
})
export class TableInPaymentComponent implements OnInit {
  public openModal = false;

  @Input() isGroupCheck: boolean = false;
  constructor() {}

  ngOnInit(): void {}

  open() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    this.openModal = true;
  }

  close() {
    this.openModal = false;
  }
}
