import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheet,
} from "@angular/material/bottom-sheet";
import { UserService } from "../../services/user.service";

@Component({
  selector: "modal-customer-name",
  templateUrl: "./customer-name.component.html",
})
export class CustomerNameComponent implements OnInit {
  public customerName = "";
  private currentCustomerName = "";
  public isNewCustomer = false;

  constructor(
    private userService: UserService,
    private _bottomSheet: MatBottomSheet,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: { customerName?: string; newUser: boolean }
  ) {
    this.currentCustomerName = data.customerName;
    this.customerName = data.customerName;
    this.isNewCustomer = data.newUser;
  }

  ngOnInit(): void {}

  close() {
    this._bottomSheet.dismiss();
  }

  alterCustomerName() {
    if (this.currentCustomerName !== this.customerName) {
      this.userService.setUserName(this.customerName);
    }

    if (this.isNewCustomer && this.customerName) {
      this.isNewCustomer = false;
    }

    this.close();
  }
}
