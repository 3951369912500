import { ErrorHandler, Injectable } from '@angular/core';
import { ApplicationInsightsService } from './application-insights.service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
   constructor(private applicationInsightsService: ApplicationInsightsService) {
      super();
   }

   handleError(error: Error) {
      try {
         this.applicationInsightsService.logException(error); // Manually log exception
      } catch (error) {}
   }
}
