import { Directive, HostListener } from '@angular/core';

@Directive({
   selector: '[appOnlyNumber]',
})
export class OnlyNumberDirective {
   constructor() {}

   @HostListener('input', ['$event'])
   onInput(event: any) {
      const inputValue = event.target.value;
      event.target.value = inputValue.replace(/\D|^0+/g, '');
   }
}
