import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DEFAULT_OPTIONS } from '@angular/material/bottom-sheet';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';
import { SharedModule } from '../shared/shared.module';
import { AboutComponent } from './components/about/about.component';
import { BindTableComponent } from './components/bind-table/bind-table.component';
import { CustomerNameComponent } from './components/customer-name/customer-name.component';
import { LangComponent } from './components/lang/lang.component';
import { LoadingComponent } from './components/loading/loading.component';
import { NavComponent } from './components/nav/nav.component';
import { NotificationInactivityComponent } from './components/notification-inactivity/notification-inactivity.component';
import { OrderCartComponent } from './components/order-cart/order-cart.component';
import { OrderHistoryComponent } from './components/order-history/order-history.component';
import { ProductDetailComponent } from './components/product-detail/product-detail.component';
import { ReviewComponent } from './components/review/review.component';
import { TableInPaymentComponent } from './components/table-in-payment/table-in-payment.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { NotificationService } from './services/notification.service';

const CORE_COMPONENTS = [
   LoadingComponent,
   LangComponent,
   ReviewComponent,
   ProductDetailComponent,
   OrderCartComponent,
   OrderHistoryComponent,
   CustomerNameComponent,
   BindTableComponent,
   TableInPaymentComponent,
   AboutComponent,
   WelcomeComponent,
   NavComponent,
   NotificationInactivityComponent,
];
const MODULES = [MaterialModule, RouterModule, SharedModule];

@NgModule({
   declarations: [CORE_COMPONENTS],
   imports: [CommonModule, FormsModule, MODULES],
   exports: [CORE_COMPONENTS],
   providers: [NotificationService, , { provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } }],
})
export class CoreModule {}
