import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { environment } from 'src/environments/environment';
import { MenuServiceConfigurationResponse } from '../../models/menu-service.model';
import { OrderCheck } from '../../models/order-check.model';
import { User } from '../../models/user.model';
import { MenuService } from '../../services/menu-service.service';
import { UserService } from '../../services/user.service';
import { transitionAnimation } from '../../utils/animations';
import { AboutComponent } from '../about/about.component';
import { CustomerNameComponent } from '../customer-name/customer-name.component';

export enum Theme {
   Light,
   Dark,
}

@Component({
   selector: 'modal-nav',
   templateUrl: './nav.component.html',
   styleUrls: ['./nav.component.scss'],
   animations: [transitionAnimation],
})
export class NavComponent implements OnInit {
   public appVersion = environment.version;
   public openModal = false;
   public isViewMode = false;
   public configuration = new MenuServiceConfigurationResponse();
   public orderCheck = new OrderCheck();
   public router: string;
   public userInfo: User;
   public fontSize = 97.75;
   public increaseDecrease = 1;

   theme = Theme;
   selectedTheme = Theme.Light;

   @ViewChild(AboutComponent, { static: true }) aboutComponent: AboutComponent;

   @HostListener('window:popstate', ['$event'])
   onPopState(event: Event) {
      if (this.openModal) {
         this.close();
      }
   }

   constructor(private menuService: MenuService, private userService: UserService, private _bottomSheet: MatBottomSheet) {}

   ngOnInit(): void {}

   open(configuration: MenuServiceConfigurationResponse, url: string, isViewMode: boolean = false) {
      this.openModal = true;
      this.configuration = configuration;
      this.router = url;
      this.isViewMode = isViewMode;
      this.userInfo = this.userService.getUser();

      if (this.userInfo.Theme === 'dark-mode') {
         this.selectedTheme = Theme.Dark;
      } else {
         this.selectedTheme = Theme.Light;
      }

      this.menuService.overflowService(false);

      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
   }

   close() {
      this.openModal = false;
      this.menuService.overflowService(true);
   }

   handleOpenModalNameCustomer() {
      this.setOpenCustomerName(this.userInfo.Name);
   }

   //-set-Customer-Name----------------------
   setOpenCustomerName(name: string, isNew: boolean = false) {
      this._bottomSheet
         .open(CustomerNameComponent, {
            data: { customerName: name, newUser: isNew },
            hasBackdrop: true,
            autoFocus: true,
         })
         .afterDismissed()
         .subscribe(() => {
            this.userInfo = this.userService.getUser();
         });
   }

   setDarkLightMode($event) {
      this.selectedTheme = $event.value;

      let setTheme: string;
      if (this.selectedTheme === 0) {
         setTheme = 'light-mode';
      } else {
         setTheme = 'dark-mode';
      }

      this.userInfo = this.userService.setTheme(this.userInfo, setTheme);
   }

   setFontSize(idd: string) {
      if (idd === 'a+') {
         if (this.fontSize < 121.75) {
            this.fontSize += 1;
         }
      } else if (idd === 'a-') {
         if (this.fontSize > 89.75) {
            this.fontSize -= 1;
         }
      } else {
         this.fontSize = 97.75;
      }

      const htmlRoot: HTMLElement = document.getElementsByTagName('html')[0] as HTMLElement;
      if (htmlRoot != null) {
         htmlRoot.style.fontSize = `${this.fontSize}%`;
         this.userService.setFontSize(this.fontSize);
      }
   }
}
