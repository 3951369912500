import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';

registerLocaleData(localePt);

const routes: Routes = [
   { path: '', redirectTo: 'empresa-nao-encontrada', pathMatch: 'full' },
   {
      path: 'empresa-nao-encontrada',
      loadChildren: () => import('./pages/company-not-found/company-not-found.module').then((m) => m.CompanyNotFoundPageModule),
   },
   {
      path: ':friendly-name/cardapio/visualizar',
      loadChildren: () => import('./pages/menu-view-mode/menu-view-mode.module').then((m) => m.MenuViewModePageModule),
   },
   {
      path: ':friendly-name/cardapio',
      loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
   },
   { path: '**', redirectTo: 'empresa-nao-encontrada' },
];

@NgModule({
   imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
   exports: [RouterModule],
})
export class AppRoutingModule {}
