<ng-container *ngIf="openModal">
    <div class="modal modal-welcome" @modal>
        <div class="resume-content">

            <div class="content-welcome">
                <div class="group-company-name">
                    <div class="company-name">
                        <small>Cardápio Digital</small>
                        {{configuration.CompanyName}}
                    </div>

                    <div class="button-go-to" (click)="close()" title="{{'GoToMenu' | i18n}}">
                        <i class="fa-light fa-arrow-right"></i>
                    </div>
                </div>

                <div class="message" [innerHtml]="configuration?.WelcomeMessage"></div>
            </div>
        </div>
    </div>
</ng-container>