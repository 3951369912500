import { animate, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, OnInit } from '@angular/core';
import { MenuServiceConfigurationResponse } from '../../models/menu-service.model';
import { OrderCheck } from '../../models/order-check.model';
import { MenuService } from '../../services/menu-service.service';

@Component({
   selector: 'modal-about',
   templateUrl: './about.component.html',
   styleUrls: ['./about.component.scss'],
   animations: [
      trigger('overlay', [
         transition(':enter', [style({ opacity: 0 }), animate('500ms', style({ opacity: 0.5 }))]),
         transition(':leave', [animate('500ms', style({ opacity: 0 }))]),
      ]),
      trigger('modal', [
         transition(':enter', [style({ top: -999 }), animate('500ms', style({ top: '50%' }))]),
         transition(':leave', [animate('500ms', style({ top: -999 }))]),
      ]),
   ],
})
export class AboutComponent implements OnInit {
   public openModal = false;
   public configuration = new MenuServiceConfigurationResponse();
   public orderCheck = new OrderCheck();
   public urlToBack: string;

   @HostListener('window:popstate', ['$event'])
   onPopState(event: Event) {
      if (this.openModal) {
         this.close();
      }
   }

   constructor(private menuService: MenuService) {}

   ngOnInit(): void {}

   open(configuration: MenuServiceConfigurationResponse, url: string) {
      this.configuration = configuration;
      this.urlToBack = url;
      history.pushState(null, 'Pedemais Menu | Sobre', this.urlToBack.split('produtos').join('sobre'));

      this.openModal = true;
      this.menuService.overflowService(false);

      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
   }

   close() {
      history.pushState(null, 'Pedemais Menu', this.urlToBack);
      this.openModal = false;
      this.menuService.overflowService(true);
   }
}
