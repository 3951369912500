<div class="header-bottom-sheet">
    <i class="fa-light fa-xmark" (click)="close()" *ngIf="!isNewCustomer"></i>
</div>
<div class="bottom-sheet-content">
    <mat-form-field class="example-full-width">
        <mat-label>{{'HowWouldYouLikeToBeCalled' | i18n}}</mat-label>
        <input matInput type="text" maxlength="30" [(ngModel)]="customerName" placeholder="Digite aqui..." autofocus>
    </mat-form-field>
    <button type="button" [disabled]="!customerName || customerName.trim().length === 0"
        (click)="alterCustomerName()">{{'Ok' |
        i18n}}</button>
</div>