<ng-container *ngIf="openModal">
    <div class="modal modal-about" @modal>
        <div class="about-content">
            <div class="header-modal">
                <div class="title-modal"> {{ 'About' | i18n }} | {{configuration.CompanyName}}</div>
                <i class="fa-light fa-xmark icon-close" (click)="close()"></i>
            </div>

            <div class="group-week">
                <div class="title-section">
                    {{'OpeningHours' | i18n}}
                </div>

                <div class="week-day" *ngFor="let weekDay of configuration?.WeekBusinessHours">
                    <div class="week-day-name">
                        {{weekDay.WeekdayName | i18n}}
                    </div>
                    <div class="week-day-start-time">
                        {{weekDay.StartTime}}
                    </div>
                    <span>{{'At' | i18n}}</span>
                    <div class="week-day-end-time">
                        {{weekDay.EndTime}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div (click)="close()" @overlay class="overlay"></div>
</ng-container>