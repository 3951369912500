import { OrderSubItem } from '../models/order';

const addDays = (date: Date, days: number): Date => {
   date.setDate(date.getDate() + days);
   return date;
};

const addHours = (date: Date, hours: number): Date => {
   date.setHours(date.getDate() + hours);
   return date;
};

const isDecimal = (quantity: number) => parseFloat(quantity.toString()) != parseInt(quantity.toString(), 10);

const toFloat = (value: number, precision: number = 2): number => {
   return parseFloat(value.toFixed(precision));
};

const copyObject = (obj: any): any => {
   return JSON.parse(JSON.stringify(obj));
};

const getProductQuantity = (subItems: OrderSubItem[], subItem: OrderSubItem, itemQuantity: number = 1) => {
   //const decimalItemsQuantity = subItems.filter((sub: OrderSubItem): boolean => isDecimal(sub.Quantity) && sub.StepIndex === subItem.StepIndex).length;
   const decimalItemsQuantity = subItem.Quantity * itemQuantity;
   return decimalItemsQuantity.toFixed(2).toString().replace('.', ',');
   //return isDecimal(subItem.Quantity) ? `1/${decimalItemsQuantity}` : subItem.Quantity * itemQuantity;
};

const normalizeAccentedChars = (s: string) => {
   return s.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export { addDays, addHours, copyObject, getProductQuantity, isDecimal, normalizeAccentedChars, toFloat };
