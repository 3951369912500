import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '../services/i18n.service';

@Pipe({
   name: 'i18n',
   pure: false,
})
export class I18nPipe implements PipeTransform {
   constructor(public i18nService: I18nService) {}

   transform(phrase: any, params?: any): string {
      return this.i18nService.getTranslation(phrase, params);
   }
}
