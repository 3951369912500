<div class="header-bottom-sheet">
    <i class="fa-light fa-xmark" (click)="close()"></i>
</div>
<div class="bottom-sheet-content" *ngIf="userInfo.CanRateCompany; else userRateCompany">
    <div class="star-rating">
        <i class="fa-duotone fa-star star{{number}}" name="star" *ngFor="let number of [1,2,3,4,5]"
            (mouseenter)="mouseEnter($event, number)" (mouseleave)="mouseLeave($event, number)"
            (click)="click($event, number)">
        </i>
    </div>

    <mat-form-field style="margin-top: 15px;">
        <mat-label>{{'Descreva aqui sua avaliação...' | i18n}}</mat-label>
        <textarea matInput name="textArea" [(ngModel)]="review" rows="4"></textarea>
    </mat-form-field>
    <button type="button" [disabled]="rating === 0" (click)="handleAddReview()">{{'Ok' |
        i18n}}</button>
</div>

<ng-template #userRateCompany>
    <div class="bottom-sheet-content">
        <div class="rate-text">
            {{'YouRatingCompany' | i18n}}
        </div>
        <hr style="margin: 20px 0;">
        <div class="star-rating">
            <i class="fa-duotone fa-star star{{number}}" [ngClass]="{'is-rating' : !userInfo.CanRateCompany}"
                name="star" *ngFor="let number of ratingArray">
            </i>
        </div>
        <hr style="margin: 20px 0;">
        <div class="description-rate">
            {{userInfo.RatingDescription}}
        </div>
    </div>
</ng-template>