import { animate, style, transition, trigger } from "@angular/animations";

export const transitionAnimation = [
  trigger("overlay", [
    transition(":enter", [
      style({ opacity: 0 }),
      animate("500ms", style({ opacity: 0.5 })),
    ]),
    transition(":leave", [animate("500ms", style({ opacity: 0 }))]),
  ]),
  trigger("modal", [
    transition(":enter", [
      style({ left: -999 }),
      animate("500ms", style({ left: "50%" })),
    ]),
    transition(":leave", [animate("500ms", style({ left: -999 }))]),
  ]),
];
