import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { HubServiceOrderCheckHistory, HubServiceTable } from '../../models/hub.model';
import { MenuServiceConfigurationResponse } from '../../models/menu-service.model';
import { OrderItem, OrderSubItem } from '../../models/order';
import { OrderCheck } from '../../models/order-check.model';
import { User } from '../../models/user.model';
import { EventService } from '../../services/event.service';
import { HubService } from '../../services/hub.service';
import { LoadingService } from '../../services/loading.service';
import { MenuService } from '../../services/menu-service.service';
import { NotificationService } from '../../services/notification.service';
import { UserService } from '../../services/user.service';
import { transitionAnimation } from '../../utils/animations';
import { getProductQuantity } from '../../utils/functions';

@Component({
   selector: 'modal-order-history',
   templateUrl: './order-history.component.html',
   styleUrls: ['./order-history.component.scss'],
   animations: [transitionAnimation],
})
export class OrderHistoryComponent implements OnInit, OnDestroy {
   private subscriptions: Subscription[] = [];
   public openModal = false;
   public canCustomerCloseTable = false;
   public orderItems: OrderItem[] = [];
   public orderCheckHistory: HubServiceOrderCheckHistory;
   public configuration = new MenuServiceConfigurationResponse();
   public orderCheck = new OrderCheck();
   public urlToBack: string;
   public user: User;
   public totalOverFilteredValue: number = 0;
   public isFilteredToUser = false;

   public listFilters = [
      { Name: 'All', Checked: true },
      { Name: 'ByName', Checked: false },
      { Name: 'YourOrder', Checked: false },
   ];

   @Output() afterBillIssued = new EventEmitter();

   @HostListener('window:popstate', ['$event'])
   onPopState(event: Event) {
      if (this.openModal) {
         this.close();
      }
   }

   constructor(
      private notificationService: NotificationService,
      private loadingService: LoadingService,
      private hubService: HubService,
      private eventService: EventService,
      private userService: UserService,
      private menuService: MenuService
   ) {}

   ngOnInit(): void {
      this.subscriptions.push(
         this.hubService.$menuServiceBillIssued.subscribe(() => {
            this.loadingService.hide();
            this.afterBillIssued.emit();
            this.close();
         })
      );

      this.subscriptions.push(
         this.hubService.$menuServiceInvalidPrinter.subscribe(() => {
            this.loadingService.hide();
            this.showAlert('DefaultPrinterNotConfigured');
         })
      );

      this.subscriptions.push(
         this.hubService.$menuServiceInternalError.subscribe(() => {
            this.loadingService.hide();
            this.showAlert('ErrorClosingBill');
         })
      );

      this.subscriptions.push(
         this.hubService.$menuServiceClosedTable.subscribe((description) => {
            this.loadingService.hide();
            this.showAlert(description || 'CardTableClosed');
         })
      );

      this.subscriptions.push(
         this.hubService.$menuServiceInvalidTable.subscribe((description) => {
            this.loadingService.hide();
            this.showAlert(description || 'ErrorClosingBill');
         })
      );

      this.subscriptions.push(
         this.eventService.orderCheckHistoryChanged$.subscribe((orderCheckHistory: HubServiceOrderCheckHistory) => {
            this.orderCheckHistory = orderCheckHistory;
            this.orderCheckHistoryFiltered();
         })
      );
   }

   open(configuration: MenuServiceConfigurationResponse, orderCheck: OrderCheck, url: string) {
      this.configuration = configuration;
      this.orderCheck = orderCheck;

      this.urlToBack = url;
      history.pushState(null, 'Pedemais Menu | Resumo', this.urlToBack.split('produtos').join('resumo'));

      this.openModal = true;
      this.menuService.overflowService(false);

      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);

      this.user = this.userService.getUser();

      this.orderCheckHistoryFiltered();
   }

   close() {
      this.menuService.overflowService(true);

      history.pushState(null, 'Pedemais Menu', this.urlToBack);
      this.openModal = false;
   }

   closeTab() {
      const table: HubServiceTable = {
         CompanyId: this.configuration.CompanyId,
         TableNumber: this.orderCheck.BindedTableNumber,
         IsGroupCheck: this.orderCheck.IsGroupCheck,
         OrderCheckCode: this.orderCheck.OrderCheckCode,
      };

      this.notificationService.showConfirmBox(null, 'AreYouSureAskBillNow', 'warning').then((ret) => {
         if (ret) {
            this.loadingService.show();
            this.hubService.closeTab(table, this.orderCheck.Password);
         }
      });
   }

   orderCheckHistoryChanged(orderCheckItems) {
      this.orderItems = orderCheckItems.map((item) => {
         const hasComplements = item.subItems && item.subItems.length > 0 && item.subItems[0].productId === item.productId;
         const isCombo = !hasComplements && item.subItems && item.subItems.length > 0;

         return {
            ProductId: item.productId,
            ProductName: item.productName,
            OriginalProductName: item.productName,
            ProductBarcode: item.productBarcode,
            Quantity: item.quantity,
            QuantityToShow: item.quantity,
            SellValue: item.sellValue * (isCombo ? item.quantity : 1),
            OriginalSellValue: 0,
            GrossValue: item.totalValue,
            TotalValue: item.totalValue,
            Observation: item.observation,
            HasComplements: hasComplements,
            ApplyServiceCharge: item.ApplyServiceCharge,
            IsSplitOnSale: item.IsSplitOnSale,
            ServesHowManyPeople: item.ServesHowManyPeople,
            SellerLogin: item.sellerLogin,
            OrderCheckItemTime: item.orderCheckItemTime ? item.orderCheckItemTime : this.setOrderCheckItemTime(item),
            ClientName: item.clientName ? item.clientName : this.setClientName(item),
            SubItems: item.subItems
               ? item.subItems
                    .filter((n) => n.productId !== item.productId)
                    .map((subitem) => {
                       return {
                          ProductId: subitem.productId,
                          ProductName: subitem.productName,
                          OriginalProductName: subitem.productName,
                          Quantity: subitem.quantity / item.quantity,
                          SellValue: subitem.sellValue,
                          OriginalSellValue: subitem.sellValue,
                          GrossValue: subitem.totalValue,
                          ProductBarcode: subitem.productBarcode,
                          TotalValue: subitem.totalValue / item.quantity,
                          Observation: subitem.observation,
                          ApplyServiceCharge: subitem.ApplyServiceCharge,
                          IsSplitOnSale: subitem.IsSplitOnSale,
                       };
                    })
               : [],
         };
      });
   }

   setClientName(items): string {
      if (items && items.subItems) {
         return (items.clientName = items.subItems[0].clientName);
      }
   }

   setOrderCheckItemTime(items): any {
      if (items && items.subItems) {
         return items.subItems[0].orderCheckItemTime;
      }
   }

   private showAlert(message) {
      if (this.openModal) {
         this.notificationService.showAlertMessage(message);
      }
   }

   ngOnDestroy() {
      if (this.subscriptions) {
         this.subscriptions.forEach((s) => s.unsubscribe());
      }
   }

   getProductQuantity(subItems: OrderSubItem[], subItem: OrderSubItem, itemQuantity: number = 1) {
      return getProductQuantity(subItems, subItem, itemQuantity);
   }

   orderCheckHistoryFiltered(filter = 'All') {
      let orderCheckItems: any;

      switch (filter) {
         case 'All':
            orderCheckItems = this.orderCheckHistory?.items ? this.orderCheckHistory.items.filter((n) => !n.isCancelled) : [];
            this.totalOverFilteredValue = 0;
            this.isFilteredToUser = false;
            break;
         case 'ByName':
            orderCheckItems = this.orderCheckHistory?.items
               ? this.orderCheckHistory.items.filter((n) => !n.isCancelled && n.clientName !== null)
               : [];

            orderCheckItems.sort((a, b) => {
               return this.compare(a, b, 'clientName');
            });

            this.totalOverFilteredValue = 0;
            this.isFilteredToUser = false;
            break;
         case 'YourOrder':
            orderCheckItems = this.orderCheckHistory?.items
               ? this.orderCheckHistory.items.filter((n) => !n.isCancelled && n.clientName === this.user.Name)
               : [];

            this.totalOverFilteredValue = this.calcTotalOverFilteredValue(orderCheckItems);
            this.isFilteredToUser = true;
            break;
      }

      this.orderCheckHistoryChanged(orderCheckItems);
   }

   calcTotalOverFilteredValue(orderCheckItems): number {
      let total = 0;

      orderCheckItems.forEach((item) => {
         total += item.totalValue;
      });

      return total;
   }

   public compare(a, b, property) {
      if (a[property] < b[property]) {
         return -1;
      }
      if (a[property] > b[property]) {
         return 1;
      }
      return 0;
   }

   handleSetFilter(filter) {
      this.orderCheckHistoryFiltered(filter);
   }
}
