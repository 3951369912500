import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheet,
} from "@angular/material/bottom-sheet";
import { Subscription } from "rxjs";
import { HubServiceOrderCheckStatus } from "../../models/hub.model";
import { MenuServiceConfigurationResponse } from "../../models/menu-service.model";
import { OrderCheck } from "../../models/order-check.model";
import { HubService } from "../../services/hub.service";
import { LoadingService } from "../../services/loading.service";
import { LocalStorageService } from "../../services/local-storage.service";
import { MenuService } from "../../services/menu-service.service";
import { NotificationService } from "../../services/notification.service";

@Component({
  selector: "modal-bind-table",
  templateUrl: "./bind-table.component.html",
})
export class BindTableComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public tableNumber: number;
  public orderCheck: OrderCheck;
  public isOpenModal: boolean = false;
  public configuration = new MenuServiceConfigurationResponse();

  constructor(
    private hubService: HubService,
    private notificationService: NotificationService,
    private loadingService: LoadingService,
    private menuService: MenuService,
    private localStorageService: LocalStorageService,
    private _bottomSheet: MatBottomSheet,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: { isOpenModal: boolean }
  ) {
    this.isOpenModal = data.isOpenModal;
  }

  ngOnInit(): void {
    this.orderCheck = this.localStorageService.getOrderCheck();

    if (this.orderCheck.BindedTableNumber > 0) {
      this.tableNumber = this.orderCheck.BindedTableNumber;
    }

    //## region hubService
    this.subscriptions.push(
      this.hubService.$menuServiceUpdateStatus.subscribe(
        (description: string) => {
          this.loadingService.hide();

          if (this.orderCheck) {
            this.orderCheck.BindedTableNumber = this.tableNumber;
            this.localStorageService.setOrderCheck(this.orderCheck);

            this.notificationService.showInfoMessage("LinkMadeSuccessfully");
          }

          this.close(this.isOpenModal);
        }
      )
    );

    this.subscriptions.push(
      this.hubService.$menuServiceOffline.subscribe(() => {
        this.loadingService.hide();
      })
    );

    this.subscriptions.push(
      this.hubService.$menuServiceInvalidTable.subscribe(() => {
        this.loadingService.hide();
        this.showInfoMessage("BindTableTableAlreadyHasItems");
      })
    );

    this.subscriptions.push(
      this.hubService.$menuServiceBindTableTableAlreadyHasItems.subscribe(
        () => {
          this.loadingService.hide();
          this.showInfoMessage("BindTableTableAlreadyHasItems");
        }
      )
    );

    this.subscriptions.push(
      this.hubService.$menuServiceBindTableInternalError.subscribe(() => {
        this.loadingService.hide();
        this.showInfoMessage("CardTableClosed");
      })
    );

    this.subscriptions.push(
      this.hubService.$menuServiceClosedTable.subscribe((description) => {
        this.loadingService.hide();
        this.showInfoMessage(description || "CardTableClosed");
      })
    );
    //## end region hubService
  }

  close(isOpenModal: boolean = false) {
    this._bottomSheet.dismiss(isOpenModal);
  }

  bindTableToOrderCheck() {
    this.configuration = this.localStorageService.getConfiguration();
    this.tableNumber = Number(this.tableNumber) || 0;

    this.menuService
      .getOrderCheckInfo(this.configuration.CompanyId, this.tableNumber)
      .subscribe(
        (ret) => {
          if (!ret.IsGroupCheck) {
            this.showTableNotFoundMessage();
            return;
          }

          this.changeTableNumber();
        },
        (err) => {
          if (err.status && err.status === 404) {
            this.showTableNotFoundMessage();
          }
        }
      );
  }

  changeTableNumber() {
    const ordercheckBindTableHasChanged =
      this.tableNumber !== this.orderCheck.BindedTableNumber;

    if (ordercheckBindTableHasChanged) {
      const orderCheckStatus: HubServiceOrderCheckStatus = {
        CompanyId: this.configuration.CompanyId,
        IsBlocked: false,
        Status: 1,
        TableNumber: Number(this.tableNumber),
        OrderCheckCode: this.orderCheck.OrderCheckCode,
        HeadCount: 1,
        Observation: null,
      };

      this.loadingService.show();
      this.hubService.updateOrderCheckStatus(orderCheckStatus);
    } else {
      this.close();
    }
  }

  private showTableNotFoundMessage() {
    this.notificationService.showAlertMessage("TableNotFound");
  }

  private showInfoMessage(message) {
    this.notificationService.showAlertMessage(message, 6);
  }

  validatorInputNumberTable(): boolean {
    const tableNumberSelected = Number(this.tableNumber) || 0;
    return tableNumberSelected > 0;
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((s) => s.unsubscribe());
    }
  }
}
