import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
@Pipe({
   name: 'money',
})
export class MoneyPipe implements PipeTransform {
   transform(
      value: number,
      currencyCode: string = 'BRL',
      display: 'symbol',
      digitsInfo: string = '1.2-2',
      locale: string = 'pt'
   ): string | null {
      return formatCurrency(value || 0, locale, getCurrencySymbol(currencyCode, 'wide'), currencyCode, digitsInfo);
   }
}
