<div class="time-content">
    <h2>{{'OopsForgotToSubmitTheOrder' | i18n}}</h2>
    {{'CartProductsWillBeAutomaticallyRemovedIn' | i18n}}

    <div class="time">
        {{time}}
    </div>

    <button type="button" (click)="alterChangeValue()">{{'KeepAndKeepAsking' | i18n}}</button>

</div>