import { Injectable } from "@angular/core";
import { Language } from "src/app/shared/models/language.model";
import { User } from "../models/user.model";
import { addDays, addHours } from "../utils/functions";
import { LocalStorageService } from "./local-storage.service";

@Injectable({ providedIn: "root" })
export class UserService {
  constructor(private localStorageService: LocalStorageService) {
    const user = this.getUser();
    if (!user.CanRateCompany) {
      user.CanRateCompany =
        addDays(new Date(user.CompanyRatedOn), 1) < new Date();
      this.localStorageService.saveUserInfo(user);
    }

    if (user.WelcomePageLastSeenOn) {
      user.CanShowWelcomePage =
        addHours(new Date(user.CompanyRatedOn), 1) > new Date();
      this.localStorageService.saveUserInfo(user);
    } else if (!user.CanShowWelcomePage) {
      user.CanShowWelcomePage = true;
      this.localStorageService.saveUserInfo(user);
    }
  }

  getUser(): User {
    const user = this.localStorageService.getUserInfo();

    if (user && user.FontSize > 1) {
      const $html = document.querySelector("html");
      $html.style.fontSize = `${user.FontSize}%`;
    }

    this.setTheme(user, user.Theme);

    return user;
  }

  setUserName(name: string): User {
    const user = this.getUser();
    user.Name = name?.trim();
    this.localStorageService.saveUserInfo(user);

    return user;
  }

  setTheme(user: User, theme: string) {
    if (theme && user.Theme !== theme) {
      user.Theme = theme;
      this.localStorageService.saveUserInfo(user);

      const $html = document.querySelector("html");
      $html.classList.toggle("dark-mode");

      if (theme !== "dark-mode") {
        const configuration = this.localStorageService.getConfiguration();
        if (
          configuration.PrimaryColor !== null &&
          configuration.PrimaryColor &&
          configuration.PrimaryColor.length > 0
        ) {
          document.body.style.setProperty(
            "--primary-color",
            configuration.PrimaryColor
          );
        }
        if (
          configuration.SecondaryColor !== null &&
          configuration.SecondaryColor &&
          configuration.SecondaryColor.length > 0
        ) {
          document.body.style.setProperty(
            "--secondary-color",
            configuration.SecondaryColor
          );
        }
        if (
          configuration.TextColor !== null &&
          configuration.TextColor &&
          configuration.TextColor.length > 0
        ) {
          document.body.style.setProperty(
            "--text-color",
            configuration.TextColor
          );
        }
      } else {
        document.body.style.setProperty("--primary-color", "#303030");
        document.body.style.setProperty("--secondary-color", "#3d3d3d");
        document.body.style.setProperty("--text-color", "#fdfdfd");
      }
    }

    return user;
  }

  setFontSize(size: number): User {
    const user = this.getUser();

    if (size && user.FontSize !== size) {
      user.FontSize = size;
      this.localStorageService.saveUserInfo(user);

      const $html = document.querySelector("html");
      $html.style.fontSize = `${size}%`;
    }

    return user;
  }

  setLanguage(language: Language): User {
    const user = this.getUser();

    if (language && user.Language?.Key !== language.Key) {
      user.Language = language;
      this.localStorageService.saveUserInfo(user);
    }

    return user;
  }

  getLanguage(): Language {
    const user = this.getUser();
    return user.Language;
  }

  setCompanyRated(rating: number, ratingDescription: string): User {
    const user = this.getUser();
    user.CompanyRatedOn = new Date();
    user.CanRateCompany = false;
    user.Rating = rating;
    user.RatingDescription = ratingDescription;
    this.localStorageService.saveUserInfo(user);

    return user;
  }

  setWelcomePageLastSeenOn(): User {
    const user = this.getUser();
    user.WelcomePageLastSeenOn = new Date();
    user.CanShowWelcomePage = false;
    this.localStorageService.saveUserInfo(user);
    return user;
  }

  resetWelcomePageLastSeenOn(): User {
    const user = this.getUser();
    user.WelcomePageLastSeenOn = null;
    user.CanShowWelcomePage = true;
    this.localStorageService.saveUserInfo(user);
    return user;
  }
}
