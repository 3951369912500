import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
   providedIn: 'root',
})
export class JsonApiService {
   constructor(private http: HttpClient) {}

   public get(url): Observable<any> {
      return this.http.get(this.getBaseUrl() + 'assets/api/' + url);
   }

   private getBaseUrl() {
      return location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/';
   }
}
