import { Injectable } from '@angular/core';
import { MenuServiceConfigurationResponse } from '../models/menu-service.model';
import { Order } from '../models/order';
import { OrderCheck } from '../models/order-check.model';
import { User } from '../models/user.model';

@Injectable({
   providedIn: 'root',
})
export class LocalStorageService {
   private _configurationStorageName = 'pede-mais-menu-configuration';
   private _orderCheckStorageName = 'pede-mais-menu-order-check';
   private _userStorageName = 'pede-mais-menu-user';
   private _orderStorageName = 'pede-mais-menu-order';
   private _hubStorageName = 'pede-mais-menu-hub';

   constructor() {}

   setConfiguration(configuration: MenuServiceConfigurationResponse) {
      if (configuration) {
         window.localStorage.setItem(this._configurationStorageName, JSON.stringify(configuration));
      }
   }

   getConfiguration(): MenuServiceConfigurationResponse {
      const parameterization = window.localStorage.getItem(this._configurationStorageName);
      const param = parameterization ? JSON.parse(parameterization) : new MenuServiceConfigurationResponse();

      const user = this.getUserInfo();
      if (user.Theme !== 'dark-mode') {
         document.body.style.setProperty('--primary-color', param.PrimaryColor);
      }

      return param;
   }

   getOrderCheck(): OrderCheck {
      const orderCheck = window.localStorage.getItem(this._orderCheckStorageName);
      return orderCheck ? JSON.parse(orderCheck) : new OrderCheck();
   }

   setOrderCheck(orderCheck: OrderCheck) {
      window.localStorage.setItem(this._orderCheckStorageName, JSON.stringify(orderCheck));
   }

   saveUserInfo(user: User) {
      if (user) {
         user.LastAccessOn = new Date();
         window.localStorage.setItem(this._userStorageName, JSON.stringify(user));
      }
   }

   getUserInfo(): User {
      const user = window.localStorage.getItem(this._userStorageName);
      return user ? JSON.parse(user) : new User();
   }

   getMenuServiceOrder(): Order {
      const order = JSON.parse(window.sessionStorage.getItem(this._orderStorageName)) as Order;
      return order;
   }

   setMenuServiceOrder(order: Order) {
      try {
         window.sessionStorage.setItem(this._orderStorageName, JSON.stringify(order));
      } catch (error) {}
   }

   removeMenuServiceOrder() {
      window.sessionStorage.removeItem(this._orderStorageName);
   }

   setHubConnectionId(connectionId: string) {
      const info = { ConnectedOn: new Date(), ConnectionId: connectionId };
      window.sessionStorage.setItem(this._hubStorageName, JSON.stringify(info));
   }
}
