import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApplicationInsightsService } from './core/services/application-insights.service';

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.scss'],
})
export class AppComponent {
   constructor(private applicationInsightsService: ApplicationInsightsService) {
      try {
         this.applicationInsightsService.logEvent(`KCMS Pedemais ${environment.version}`);
      } catch (error) {}
   }
}
