<ng-container *ngIf="openModal">
  <div class="modal modal-detail-products" @modal>
    <div class="img-product-zoom" *ngIf="orderItem.ImageUrl">
      <div class="image-zoom">
        <img loading="lazy" src="{{ imageUrl }}" alt="{{ orderItem.ProductName }}" />
        <i class="fa-light fa-xmark btn-close-image-zoom" (click)="handleImageZoom(false)"></i>
      </div>
    </div>

    <div class="product-content">
      <div *ngIf="!orderItem.ImageUrl" class="header-modal">
        <i class="fa-light fa-arrow-left" (click)="close()"></i>
      </div>

      <div class="group-detail-product">
        <div class="img-product" *ngIf="orderItem.ImageUrl" (click)="handleImageZoom(true, orderItem.ImageUrl)">
          <i class="fa-light fa-arrow-left icon-close icon-close-detail-product" (click)="close()"></i>
          <img loading="lazy" src="{{ orderItem.ImageUrl }}" alt="{{ orderItem.ProductName }}" />
          <div class="icon-zoom-image">
            <i class="fa-light fa-magnifying-glass-plus"></i>
          </div>
        </div>

        <div class="goup-inf-product">
          <div class="detail-product">
            <div *ngIf="orderItem.IsProductsAgeRestricted" class="warning-18">
              <i class="fa-duotone fa-triangle-exclamation"></i>
              {{ "AgeRestricted" | i18n }}
            </div>

            <div *ngIf="orderItem.ProductIsCompositionMember" class="all-you-can-eat">
              {{ "EvenInTheAllYouCanEat" | i18n }}
            </div>

            <div class="group-product-name">
              <div class="product-name">
                {{ orderItem.ProductName }}
              </div>

              <div class="product-server-many" *ngIf="orderItem.ServesHowManyPeople > 0">
                <i class="fa-duotone fa-users"></i>
                {{
                "Server"
                | i18n
                : [
                orderItem.ServesHowManyPeople,
                (orderItem.ServesHowManyPeople > 1 ? "ServesMorePerson" : "ServesOnePerson") | i18n
                ]
                }}
              </div>
            </div>
            <div class="product-additional-inf">
              {{ orderItem.AdditionalInfo }}
            </div>

            <div *ngIf="!orderItem.ProductIsCompositionMember" class="product-sell-value">
              {{ orderItem.ProductTypeName === "Combo" ? "A partir de " : "" }}
              {{
              (orderItem.ProductTypeName === "Combo" ? orderItem.ProductStepMinValue : orderItem.SellValue) | money
              }}
            </div>
          </div>

          <!--Combo------------------>
          <div *ngIf="orderItem.ProductSteps && orderItem.ProductSteps.length > 0">
            <section *ngFor="let step of orderItem.ProductSteps">
              <div class="title-section" [id]="'step-index-' + step.Position">
                <div class="left">
                  <span>{{ step.Name }}</span>
                  <p *ngIf="!isViewMode">{{ step.Quantity }} {{ "of" | i18n }} {{ step.MaxQuantity }}</p>
                </div>
                <div class="rigth">
                  <div *ngIf="step.MinQuantity > 0 && step.Quantity !== step.MaxQuantity && !isViewMode"
                    class="required">
                    {{ "Required" | i18n }}
                  </div>
                  <i class="fa-duotone fa-check-double checked" *ngIf="step.Quantity === step.MaxQuantity"></i>
                </div>
              </div>

              <div class="group-add-items" *ngIf="step.MaxQuantity > 1; else productRadio">
                <div class="items-to-add" *ngFor="let item of step.StepItems">
                  <div class="list-item">
                    <div>
                      {{ item.ProductName }}<br />
                      <div class="product-additional-inf">
                        {{ item.AdditionalInfo }}
                      </div>
                      <span>{{ item.SellPrice | money }}</span>
                    </div>
                    <img loading="lazy" *ngIf="item.SmallImageUrl" src="{{ item.SmallImageUrl }}"
                      (click)="handleImageZoom(true, item.ImageUrl)" alt="{{ item.ProductName }}" />
                  </div>
                  <div class="btn-more-minus" *ngIf="!isViewMode">
                    <button type="button" [disabled]="item.QuantityToShow === 0" class="btn-minus"
                      (click)="decrementStepProduct(step, item)">
                      -
                    </button>
                    {{ item.QuantityToShow }}
                    <button type="button" class="btn-more" [disabled]="
                        item.QuantityToShow === step.MaxQuantity ||
                        step.Quantity === step.MaxQuantity ||
                        step.ProductStepSelectedItemsCount >= step.MaxQuantity ||
                        (!step.CanRepeatSameItem && item.QuantityToShow > 0)
                      " (click)="incrementStepProduct(step, item)">
                      +
                    </button>
                  </div>
                </div>
              </div>
              <ng-template #productRadio>
                <mat-radio-group class="radio-group" *ngIf="!isViewMode">
                  <mat-radio-button class="radio-button" *ngFor="let item of step.StepItems" [value]="item"
                    [checked]="item.QuantityToShow > 0" (click)="checkStepProduct($event, step, item)">
                    <div class="list-item">
                      <div>
                        {{ item.ProductName }}<br />
                        <div class="product-additional-inf">
                          {{ item.AdditionalInfo }}
                        </div>
                        <span>{{ item.SellPrice | money }}</span>
                      </div>
                      <img loading="lazy" *ngIf="item.SmallImageUrl" src="{{ item.SmallImageUrl }}"
                        (click)="handleImageZoom(true, item.ImageUrl)" alt="{{ item.ProductName }}" />
                    </div>
                  </mat-radio-button>
                </mat-radio-group>

                <div class="radio-group" *ngIf="isViewMode">
                  <div class="radio-button" *ngFor="let item of step.StepItems">
                    <div class="list-item">
                      <div>
                        {{ item.ProductName }}<br />
                        <div class="product-additional-inf">
                          {{ item.AdditionalInfo }}
                        </div>
                        <span>{{ item.SellPrice | money }}</span>
                      </div>
                      <img loading="lazy" *ngIf="item.SmallImageUrl" src="{{ item.SmallImageUrl }}"
                        (click)="handleImageZoom(true, item.ImageUrl)" alt="{{ item.ProductName }}" />
                    </div>
                  </div>
                </div>
              </ng-template>
            </section>
          </div>
          <section *ngIf="!isViewMode">
            <div class="title-section">{{'AnyNotes' | i18n}}</div>
            <mat-form-field style="margin-top: 15px;">
              <mat-label>{{'AddANote' | i18n}}</mat-label>
              <textarea matInput name="textArea" id="orderItemObservation" [(ngModel)]="orderItem.Observation"
                rows="3"></textarea>
            </mat-form-field>
          </section>
        </div>
      </div>
    </div>
    <div class="group-buttons-footer" *ngIf="!isViewMode">
      <div class="btn-more-minus">
        <button type="button" class="btn-minus" (click)="decrementProduct()">-</button>
        {{ orderItem.Quantity }}
        <button type="button" class="btn-more" (click)="incrementProduct()">+</button>
      </div>
      <button type="button" class="btn-add" (click)="saveProduct()" [disabled]="!stepItemsRequiredValuesComplete">
        <span *ngIf="!isEdit">{{ "Add" | i18n }}</span>
        <span *ngIf="isEdit">{{ "Update" | i18n }}</span>
        <span> {{ orderItem.TotalValue | money }}</span>
      </button>
    </div>
  </div>
  <div (click)="close()" @overlay class="overlay"></div>
</ng-container>