<ng-container *ngIf="openModal">
    <div class="modal modal-order-history" @modal>
        <div class="resume-content">
            <div class="header-modal">
                <i class="fa-light fa-arrow-left" (click)="close()"></i>
                <div class="title-modal"> {{'OrderSummary' | i18n}} </div>
            </div>

            <div class="group-items-resume" *ngIf="order.Items && order.Items.length > 0; else resumeEmpty">
                <div class="item-order" *ngFor="let item of order?.Items; index as i">
                    <div class="item" (click)="editItem(item, i)">
                        <div class="group-name-sell-value">
                            <div class="group-item">
                                <div class="quantity-item">
                                    {{item.Quantity}} x
                                </div>
                                <div class="name-item">
                                    {{item.ProductName}}
                                </div>
                            </div>
                            <div class="sell-value">
                                {{( item.ProductTypeName.toLowerCase() === 'combo' ?
                                item.TotalValue :
                                item.SellValue * item.Quantity
                                ) | money}}
                            </div>
                        </div>
                        <div class="complement-item" *ngFor="let subItem of item.SubItems">
                            {{ item.HasComplements ? '+' : '&bull;' }}
                            {{getProductQuantity(item.SubItems, subItem, item.Quantity)}} x
                            {{subItem.ProductName}}

                            <div class="sell-value" *ngIf="item.HasComplements && subItem.SellValue > 0">
                                {{subItem.TotalValue * item.Quantity | money}}
                            </div>
                        </div>
                        <div class="observation-item" *ngIf="item.Observation">
                            **{{item.Observation}}
                        </div>
                    </div>
                    <i class="fa-sharp fa-light fa-circle-xmark icon-delete" (click)="removeItem(item)"></i>
                </div>
            </div>

            <ng-template #resumeEmpty>
                <div class="group-items-resume-empty">
                    <i class="fa-duotone fa-cart-xmark"></i>
                    {{'CartIsEmpty' | i18n}}
                </div>
            </ng-template>


        </div>
        <div class="group-buttons-footer" *ngIf="order.Items && order.Items.length > 0">
            <div class="total-order">
                <small>{{'Total' | i18n}}</small>
                {{order.TotalValue | money}}
            </div>
            <button type="button" class="btn-next" (click)="sendOrderToMenuService()"
                [disabled]="!order.ItemsQuantity || order.ItemsQuantity === 0">
                {{'ProceedCheckout' | i18n}}
                <i class="fa-light fa-arrow-right"></i>
            </button>
        </div>
    </div>
</ng-container>

<modal-product-detail></modal-product-detail>