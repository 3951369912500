import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Subscription } from 'rxjs';
import { I18nService } from 'src/app/shared/services/i18n.service';
import { HubServiceNotification } from '../../models/hub.model';
import { MenuServiceConfigurationResponse } from '../../models/menu-service.model';
import { Order, OrderItem, OrderSubItem } from '../../models/order';
import { OrderCheck } from '../../models/order-check.model';
import { User } from '../../models/user.model';
import { HubService } from '../../services/hub.service';
import { LoadingService } from '../../services/loading.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { MenuService } from '../../services/menu-service.service';
import { NotificationService } from '../../services/notification.service';
import { OrderService } from '../../services/order.service';
import { UserService } from '../../services/user.service';
import { getProductQuantity } from '../../utils/functions';
import { NotificationInactivityComponent } from '../notification-inactivity/notification-inactivity.component';
import { ProductDetailComponent } from '../product-detail/product-detail.component';
import { IdleService } from './../../services/idle-detect.service';
import { transitionAnimation } from './../../utils/animations';

@Component({
   selector: 'modal-order-cart',
   templateUrl: './order-cart.component.html',
   styleUrls: ['./order-cart.component.scss'],
   animations: [transitionAnimation],
})
export class OrderCartComponent implements OnInit, OnDestroy {
   private subscriptions: Subscription[] = [];
   public openModal = false;
   public isSendingOrder = false;
   private orderTimeout;
   public urlToBack: string;
   public orderCheck = new OrderCheck();
   public configuration = new MenuServiceConfigurationResponse();
   public userInfo = new User();

   @Input() order: Order;

   @ViewChild(ProductDetailComponent, { static: true })
   productDetailComponent: ProductDetailComponent;
   @Output() afterOrderPlaced = new EventEmitter();

   @HostListener('window:popstate', ['$event'])
   onPopState(event: Event) {
      if (this.openModal) {
         this.close();
      }
   }

   constructor(
      private localStorageService: LocalStorageService,
      private hubService: HubService,
      private i18nService: I18nService,
      private userService: UserService,
      private notificationService: NotificationService,
      private loadingService: LoadingService,
      private orderService: OrderService,
      private menuService: MenuService,
      private _bottomSheet: MatBottomSheet,
      private idleService: IdleService
   ) {
      this.checkInactivity();
   }

   checkInactivity() {
      this.idleService.getIdleStream(120000).subscribe((idle: boolean): void => {
         if (
            idle &&
            !this.openModal &&
            this.order &&
            this.order.Items &&
            this.order.Items.length > 0 &&
            this.configuration &&
            this.configuration.CompanyId > 0
         ) {
            this._bottomSheet
               .open(NotificationInactivityComponent, {
                  disableClose: true,
                  hasBackdrop: true,
                  data: { isCart: true },
               })
               .afterDismissed()
               .subscribe((ret) => {
                  if (!ret) {
                     this.orderService.clearOrder(this.configuration.CompanyId);
                  }
               });
         }
      });
   }

   ngOnInit(): void {
      this.configuration = this.localStorageService.getConfiguration();

      this.subscriptions.push(
         this.hubService.$menuServiceOrderCreated.subscribe(() => {
            this.orderSended();
         })
      );

      this.subscriptions.push(
         this.hubService.$menuServiceDuplicateOrder.subscribe(() => {
            this.orderSended();
         })
      );

      this.subscriptions.push(
         this.hubService.$menuServiceProductNotFound.subscribe((notification: HubServiceNotification) => {
            this.resetTimer();
            const message = this.i18nService.getTranslation('BarcodesNotRegistered', [notification.description]);
            this.showInfoMessage(message);
         })
      );

      this.subscriptions.push(
         this.hubService.$menuServiceOffline.subscribe(() => {
            this.resetTimer();
         })
      );

      this.subscriptions.push(
         this.hubService.$menuServiceInsufficientCredit.subscribe(() => {
            this.resetTimer();
            this.showInfoMessage('ToOrderPleaseCallAttendant');
         })
      );

      this.subscriptions.push(
         this.hubService.$menuServiceOrderWithWrongCompany.subscribe(() => {
            this.resetTimer();
            this.showInfoMessage('SendOrderError');
         })
      );
   }

   open(url: string) {
      this.openModal = true;
      this.orderCheck = this.localStorageService.getOrderCheck();

      this.urlToBack = url;
      history.pushState(null, 'Pedemais Menu | Carrinho', this.urlToBack.split('produtos').join('carrinho'));

      this.menuService.overflowService(false);

      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
   }

   close() {
      history.pushState(null, 'Pedemais Menu', this.urlToBack);
      this.openModal = false;

      this.menuService.overflowService(true);
   }

   editItem(orderItem: OrderItem, index: number) {
      this.productDetailComponent.openEdition(orderItem, index);
   }

   removeItem(orderItem: OrderItem) {
      this.orderService.removeProduct(orderItem);
   }

   getProductQuantity(subItems: OrderSubItem[], subItem: OrderSubItem, itemQuantity: number = 1) {
      return getProductQuantity(subItems, subItem, itemQuantity);
   }

   sendOrderToMenuService() {
      this.sendOrder();
   }

   async sendOrder() {
      this.userInfo = this.userService.getUser();
      this.isSendingOrder = true;

      this.orderTimeout = setTimeout(async () => {
         this.showInfoMessage('OrderNotSentResubmit');
         this.loadingService.hide();
      }, 15000);

      this.loadingService.show();

      const hubServiceOrder = this.orderService.getHubServiceOrder(this.orderCheck, this.userInfo.Name);

      this.hubService.createOrder(hubServiceOrder, this.orderCheck.Password);
   }

   private orderSended() {
      this.orderService.clearOrder(this.configuration.CompanyId);
      this.resetTimer();
      this.notificationService.showSuccessMessage('OrderPlacedSuccessfully', 6);
      this.afterOrderPlaced.emit();
      this.close();
   }

   private resetTimer() {
      this.clearOrderTimeout();
      this.loadingService.hide();
      this.isSendingOrder = false;
   }

   private clearOrderTimeout() {
      if (this.orderTimeout) {
         clearTimeout(this.orderTimeout);
      }
   }

   private showInfoMessage(message) {
      if (this.openModal) {
         this.notificationService.showAlertMessage(message, 6);
      }
   }

   ngOnDestroy() {
      this.clearOrderTimeout();

      if (this.subscriptions) {
         this.subscriptions.forEach((s) => s.unsubscribe());
      }
   }
}
