export class Language {
   public Key: string;
   public Name: string;
   public Country: string;
   public FlagIcon: string;
   public LocaleCode: string;

   constructor() {
      this.Key = 'pt';
      this.Name = 'Português';
      this.Country = 'Brazil';
      this.FlagIcon = 'br';
      this.LocaleCode = 'pt-BR';
   }
}
