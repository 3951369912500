import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable, animationFrameScheduler, fromEvent, merge, of } from 'rxjs';
import { auditTime, delay, distinctUntilChanged, map, share, startWith, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class IdleService {
   private static get USER_ACTIVITY_EVENTS(): ReadonlyArray<string> {
      return ['keypress', 'keydown', 'click', 'contextmenu', 'mousemove', 'scroll', 'touchmove', 'touchstart'];
   }

   private readonly userActivity$: Observable<void>;

   constructor(@Inject(DOCUMENT) private readonly document: Document) {
      this.userActivity$ = merge
         .apply(
            null,
            IdleService.USER_ACTIVITY_EVENTS.map((event: string): Observable<Event> => {
               return fromEvent(this.document, event);
            })
         )
         .pipe(
            auditTime(0, animationFrameScheduler),
            map((): void => void 0),
            share()
         );
   }

   public getIdleStream(delayDuration: number = 5000): Observable<boolean> {
      const userActivity$: Observable<boolean> = this.userActivity$.pipe(
         startWith(null),
         map(() => false)
      );

      const idle$: Observable<boolean> = userActivity$.pipe(
         switchMap(() => {
            return of(true).pipe(delay(delayDuration));
         })
      );

      return merge(userActivity$, idle$).pipe(distinctUntilChanged());
   }
}
