import { Injectable } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';
import { Language } from '../models/language.model';
import { JsonApiService } from './json-api.service';

@Injectable()
export class I18nService {
   private currentLanguage = new Language();
   private translationPage: {};
   private languages: Language[] = [
      {
         Key: 'pt',
         Name: 'Português',
         Country: 'Brazil',
         FlagIcon: 'br',
         LocaleCode: 'pt-BR',
      },
      {
         Key: 'en',
         Name: 'English',
         Country: 'United States',
         FlagIcon: 'us',
         LocaleCode: 'en-US',
      },
      {
         Key: 'es',
         Name: 'Español',
         Country: 'Spain',
         FlagIcon: 'es',
         LocaleCode: 'es-ES',
      },
   ];

   constructor(private jsonApiService: JsonApiService, private userService: UserService) {
      this.initLanguage();
      this.fetch(this.currentLanguage.Key);
   }

   private initLanguage() {
      const language = this.userService.getLanguage();
      // const language = this.languages.find((it) => {
      //    return it.Key === localeCode;
      // });
      this.setCurrentLanguage(language);
   }

   private fetch(languageCode: string) {
      const url = `langs/${languageCode.toLowerCase()}.json`;
      this.jsonApiService.get(url).subscribe((page) => {
         this.translationPage = page;
      });
   }

   public getLanguages(): Language[] {
      return this.languages;
   }

   public getCurrentLanguage(): Language {
      return this.currentLanguage;
   }

   public setCurrentLanguage(language: Language) {
      this.userService.setLanguage(language);
      this.currentLanguage = language;
      this.fetch(language.Key);
   }

   public getTranslation(phrase: string, params?: string[]): string {
      let translatedText = this.translationPage && this.translationPage[phrase] ? this.translationPage[phrase] : phrase;

      if (params && params.length > 0) {
         params.forEach((param, index) => {
            if (translatedText.includes(`{${index}}`)) {
               const trans = this.getTranslation(param);
               translatedText = translatedText.replace(`{${index}}`, trans);
            }
         });
      }
      return translatedText;
   }

   public getCurrentLocaleCode(): string {
      return this.currentLanguage.LocaleCode;
   }

   public getCurrentLanguageKey(): string {
      return this.currentLanguage.Key.toUpperCase();
   }
}
