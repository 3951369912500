import { Injectable } from '@angular/core';
import { MetaDefinition } from '@angular/platform-browser';
import chroma from 'chroma-js';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
   MenuServiceConfigurationResponse,
   MenuServiceProduct,
   OrderCheckCustomerResponse,
} from '../models/menu-service.model';
import { ApiService } from './api.service';
import { DocumentService } from './document.service';
import { ThemeService } from './theme.service';

@Injectable({
   providedIn: 'root',
})
export class MenuService {
   private themeApplyed = new BehaviorSubject(false);
   public onThemeApplyed$ = this.themeApplyed.asObservable();
   private domainName = 'MenuService';
   private hourFormat = 'HH:mm:ss';

   constructor(
      private apiService: ApiService,
      private documentService: DocumentService,
      private themeService: ThemeService
   ) {}

   getConfiguration(friendlyUrl: string): Observable<MenuServiceConfigurationResponse> {
      return this.apiService.get(`${this.domainName}/Configuration/${friendlyUrl}`).pipe(
         map((configuration: MenuServiceConfigurationResponse) => {
            if (configuration.WeekBusinessHours && configuration.WeekBusinessHours.length > 0) {
               configuration.StoreIsOpen = this.storeIsOpen(configuration);
            } else {
               configuration.StoreIsOpen = true;
            }

            return configuration;
         })
      );
   }

   setAppConfiguration(configuration: MenuServiceConfigurationResponse) {
      if (configuration.PrimaryColor !== null && configuration.PrimaryColor && configuration.PrimaryColor.length > 0) {
         this.themeService.setVariable('--primary-color', configuration.PrimaryColor);
      }
      if (
         configuration.SecondaryColor !== null &&
         configuration.SecondaryColor &&
         configuration.SecondaryColor.length > 0
      ) {
         this.themeService.setVariable('--secondary-color', configuration.SecondaryColor);

         let color = chroma(configuration.SecondaryColor);

         if (chroma(configuration.SecondaryColor).luminance() < 0.5) {
            this.themeService.setVariable('--text-title-category', color.brighten(3));
         } else {
            this.themeService.setVariable('--text-title-category', color.darken(3));
         }
      }
      if (configuration.TextColor !== null && configuration.TextColor && configuration.TextColor.length > 0) {
         this.themeService.setVariable('--text-color', configuration.TextColor);
      }

      this.documentService.setDocumentTitle(configuration.CompanyName);

      const ogTitle: MetaDefinition = { property: 'og:title', content: configuration.CompanyName };
      this.documentService.updateMetaTag(ogTitle, true);

      if (configuration.CompanyLogoUrl) {
         const logo: MetaDefinition = { property: 'og:image', content: configuration.CompanyLogoUrl };
         this.documentService.updateMetaTag(logo, true);
      }

      this.themeApplyed.next(true);
   }

   getOrderCheckCustomer(companyId: number, orderCheckCode: number): Observable<OrderCheckCustomerResponse> {
      return this.apiService.get(`${this.domainName}/OrderCheckCustomer/${companyId}/${orderCheckCode}`);
   }

   getProducts(companyId: number, languageCode: string): Observable<MenuServiceProduct[]> {
      return this.apiService.post(`${this.domainName}/ProductsV2`, {
         companyId,
         languageCode: languageCode ? languageCode.toUpperCase() : languageCode,
      });
   }

   getOrderCheckInfo(companyId: number, orderCheckCode: number): Observable<any> {
      return this.apiService.get(`${this.domainName}/OrderCheck/${companyId}/${orderCheckCode}`);
   }

   private storeIsOpen(parameterization: MenuServiceConfigurationResponse): boolean {
      let storeIsOpen: boolean;
      const weekDay = moment().format('dddd');

      if (parameterization.WeekBusinessHours) {
         const weekBusinessDay = parameterization.WeekBusinessHours.filter(
            (n) => n.WeekdayName.toLowerCase() === weekDay.toLowerCase()
         );
         const now = moment();

         if (weekBusinessDay.length > 0) {
            storeIsOpen = weekBusinessDay.some((n) => {
               const start = moment(n.StartTime, this.hourFormat);
               const end = moment(n.EndTime, this.hourFormat);

               if (end.get('hour') < start.get('hour')) {
                  return now.isBetween(start, end.add(1, 'days'), null, '[]');
               } else {
                  return now.isBetween(start, end, null, '[]');
               }
            });
         }

         if (!storeIsOpen) {
            const yesterday = moment().subtract(1, 'days').format('dddd');

            const weekBusinessDay = parameterization.WeekBusinessHours.filter(
               (n) =>
                  n.WeekdayName.toLowerCase() === yesterday.toLowerCase() &&
                  moment(n.EndTime, this.hourFormat).get('hour') < moment(n.StartTime, this.hourFormat).get('hour')
            );

            if (weekBusinessDay.length > 0) {
               storeIsOpen = weekBusinessDay.some((n) => {
                  const start = moment(n.StartTime, this.hourFormat).subtract(1, 'days');
                  const end = moment(n.EndTime, this.hourFormat);

                  return now.isBetween(start, end, null, '[]');
               });
            }
         }
      }

      return storeIsOpen;
   }

   overflowService(overflow: boolean = true) {
      const $html = document.querySelector('html');
      if (overflow) {
         $html.style.overflow = 'auto';
      } else {
         $html.style.overflow = 'hidden';
      }
   }
}
