import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheet,
} from "@angular/material/bottom-sheet";
import { User } from "../../models/user.model";
import { ReviewService } from "../../services/review.service";
import { UserService } from "../../services/user.service";

@Component({
  selector: "modal-review",
  templateUrl: "./review.component.html",
  styleUrls: ["./review.component.scss"],
})
export class ReviewComponent implements OnInit {
  public rating = 0;
  public review = "";
  public companyId!: number;
  public userInfo: User;
  public ratingArray = [];

  constructor(
    private userService: UserService,
    private reviewService: ReviewService,
    private _bottomSheet: MatBottomSheet,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: { companyId: number }
  ) {
    this.companyId = data.companyId;
  }

  ngOnInit(): void {
    this.userInfo = this.userService.getUser();

    if (!this.userInfo.CanRateCompany) {
      for (let i = 1; i <= this.userInfo.Rating; i++) {
        this.ratingArray.push(i);
      }
    }
  }

  close() {
    this._bottomSheet.dismiss();
  }

  handleAddReview() {
    const userInfo = this.userService.getUser();
    const userName = userInfo.Name || "Anônimo";

    this.reviewService
      .save(this.companyId, this.rating, this.review, userName)
      .subscribe(
        (ret) => {
          this.userService.setCompanyRated(this.rating, this.review);
          this.close();
        },
        (err) => {
          console.error(err);
          this.close();
        }
      );
  }

  click($event: any, quantity: number) {
    for (let i = 1; i <= 5; i++) {
      const element = document.getElementsByClassName(`star${i}`)[0];

      if (element) element.classList.remove("filled");
    }

    for (let i = 1; i <= quantity; i++) {
      const element = document.getElementsByClassName(`star${i}`)[0];

      if (element) element.classList.add("filled");
    }

    this.rating = quantity;
  }

  mouseEnter($event: any, quantity: number) {
    for (let i = 1; i <= quantity; i++) {
      const element = document.getElementsByClassName(`star${i}`)[0];

      if (element) element.classList.add("active");
    }
  }

  mouseLeave($event: any, quantity: number) {
    for (let i = 1; i <= 5; i++) {
      const element = document.getElementsByClassName(`star${i}`)[0];

      if (element) element.classList.remove("active");
    }
  }
}
