import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nPipe } from './pipes/i18n.pipe';
import { I18nService } from './services/i18n.service';
import { MoneyPipe } from './pipes/money.pipe';
import { OnlyNumberDirective } from './directives/only-number.directive';

@NgModule({
   declarations: [I18nPipe, MoneyPipe, OnlyNumberDirective],
   imports: [CommonModule],
   exports: [I18nPipe, MoneyPipe, OnlyNumberDirective],
   providers: [I18nService, MoneyPipe, OnlyNumberDirective],
})
export class SharedModule {}
