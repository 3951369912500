export class OrderCheck {
   public FriendlyUrl: string;
   public OrderCheckCode: number;
   public IsGroupCheck: boolean;
   public BindedTableNumber: number;
   public OnPayingState: boolean;
   public OrderCheckCustomer: any;
   public Password: string;
   public CardId: string;
   public CreatedOn: Date;

   constructor() {
      this.OrderCheckCode = 0;
      this.IsGroupCheck = false;
      this.BindedTableNumber = 0;
      this.OnPayingState = false;
      this.CreatedOn = new Date();
   }
}
