import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Component({
   selector: 'bottom-sheet-notification-inactivity',
   templateUrl: './notification-inactivity.component.html',
   styleUrls: ['./notification-inactivity.component.scss'],
})
export class NotificationInactivityComponent implements OnInit, OnDestroy {
   public time = 60;
   public open = false;
   public title: string;
   public subTitle: string;

   constructor(private _bottomSheet: MatBottomSheet) {}

   ngOnInit(): void {
      this.open = true;

      this.runTime();
   }

   runTime() {
      setTimeout(() => {
         if (this.time > 0) {
            this.time -= 1;
            if (this.open) this.runTime();
         } else {
            this.close();
         }
      }, 1000);
   }

   close() {
      this._bottomSheet.dismiss(false);
      this.open = false;
   }

   alterChangeValue() {
      this._bottomSheet.dismiss(true);
      this.open = false;
   }

   ngOnDestroy(): void {
      this._bottomSheet.ngOnDestroy();
   }
}
