<div class="header-bottom-sheet">
    <i class="fa-light fa-xmark" (click)="close(false)"></i>
</div>
<div class="bottom-sheet-content">
    <mat-form-field class="example-full-width">
        <mat-label>{{'TableNumber' | i18n}}</mat-label>
        <input appOnlyNumber matInput type="text" maxlength="30" inputmode="numeric" [(ngModel)]="tableNumber"
            autofocus>
    </mat-form-field>
    <button type="button" [disabled]="!validatorInputNumberTable()" (click)="bindTableToOrderCheck()">{{'Ok' |
        i18n}}</button>
</div>