import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { I18nService } from 'src/app/shared/services/i18n.service';
import swal from 'sweetalert2';

@Injectable()
export class NotificationService {
   private defaultSettings = {
      timeOut: 2000,
      positionClass: 'toast-top-center',
      // positionClass: 'toast-center-center',
      preventDuplicates: false,
      progressBar: true,
      tapToDismiss: true,
      enableHtml: true,
   };

   constructor(private i18n: I18nService, private toastr: ToastrService) {}

   showSuccessMessage(content?: string, timeout?: number, title?: string, params?: string[]): number {
      this.setTimeOut(timeout);
      if (!content) {
         content = 'OperationSuccessful';
      }
      const notification = this.toastr.success(this.i18n.getTranslation(content, params), title, this.defaultSettings);
      return notification ? notification.toastId : null;
   }

   showInfoMessage(content?: string, timeout?: number, title?: string, params?: string[]): number {
      this.setTimeOut(timeout);
      const notification = this.toastr.info(this.i18n.getTranslation(content, params), title, this.defaultSettings);
      return notification ? notification.toastId : null;
   }

   showAlertMessage(content?: string, timeout?: number, title?: string, params?: string[]): number {
      this.setTimeOut(timeout);
      if (!content) {
         content = 'Processing';
      }
      const notification = this.toastr.warning(this.i18n.getTranslation(content, params), title, this.defaultSettings);
      return notification ? notification.toastId : null;
   }

   clearNotification(notificationId: number) {
      this.toastr.clear(notificationId);
   }

   private setTimeOut(timeout?) {
      if (timeout !== undefined && timeout != null) {
         this.defaultSettings.timeOut = timeout * 1000;
      } else {
         this.defaultSettings.timeOut = 2000;
      }
   }

   showAlertBox(content: string, title?: string): void {
      swal({
         type: 'info',
         title: title ? this.i18n.getTranslation(title) : null,
         text: this.i18n.getTranslation(content),
         cancelButtonClass: 'swal2-cancel-hiden',
      });
   }

   async showConfirmBox(title: string, text: string, typeAction, confirmText: string = 'Yes', cancelText: string = 'No'): Promise<boolean> {
      const result = await swal({
         title: this.i18n.getTranslation(title),
         text: this.i18n.getTranslation(text),
         type: typeAction,
         showCancelButton: true,
         confirmButtonColor: '#4267b2',
         cancelButtonColor: '#d33',
         reverseButtons: true,
         confirmButtonText: this.i18n.getTranslation(confirmText),
         cancelButtonText: this.i18n.getTranslation(cancelText),
      });

      if (result.value) {
         return true;
      } else {
         return false;
      }
   }
}
