<ng-container *ngIf="openModal">
    <div class="modal modal-order-history" @modal>
        <div class="resume-content">
            <div class="header-modal">
                <i class="fa-light fa-arrow-left" (click)="close()"></i>
                <div class="title-modal"> {{ 'SummaryOrder' | i18n : [(orderCheck?.IsGroupCheck ? 'Table' : 'Card') |
                    i18n, orderCheck.OrderCheckCode] }} </div>
            </div>

            <mat-radio-group class="radio-group" *ngIf="orderCheck?.IsGroupCheck">
                <mat-radio-button class="radio-button" *ngFor="let filter of listFilters" [value]="filter"
                    [checked]="filter.Checked" (click)="handleSetFilter(filter.Name)">
                    {{filter.Name | i18n}}
                </mat-radio-button>
            </mat-radio-group>

            <hr style="margin: 10px 0;" *ngIf="orderCheck?.IsGroupCheck">

            <div class="group-items-resume" *ngIf="orderItems && orderItems.length > 0; else orderItemsEmpty">
                <div class="item-order" *ngFor="let item of orderItems">
                    <div class="group-name-sell-value">
                        <div class="group-item">
                            <div class="quantity-item">
                                {{item.Quantity}} x
                            </div>
                            <div class="name-item">
                                {{item.ProductName}}
                            </div>
                        </div>
                        <div class="sell-value">
                            {{item.SellValue | money}}
                        </div>
                    </div>
                    <div class="complement-item" *ngFor="let complement of item.SubItems">
                        {{ item.HasComplements ? '+' : '&bull;' }}
                        {{getProductQuantity(item.SubItems, complement, item.Quantity)}} x
                        {{complement.ProductName}}

                        <div class="sell-value" *ngIf="item.HasComplements && complement.SellValue > 0">
                            {{complement.TotalValue * item.Quantity | money}}
                        </div>
                    </div>
                    <div class="observation-item" *ngIf="item.Observation">
                        **{{item.Observation}}
                    </div>
                    <div class="made-by">
                        {{'Order' | i18n}} {{item.SellerLogin ? ' pelo Garçom ' + item.SellerLogin : item.ClientName &&
                        item.ClientName.length >
                        0 ? ' por ' + item.ClientName : 'pelo Cliente' }} {{'At'| i18n}}
                        {{item.OrderCheckItemTime | date:'dd/MM/yyyy HH:mm' }}
                    </div>
                </div>
            </div>

            <ng-template #orderItemsEmpty>
                <div class="group-items-resume-empty">
                    <i class="fa-sharp fa-light fa-empty-set"></i>
                    {{'OrderEmpty' | i18n}}
                </div>
            </ng-template>
        </div>
        <div class="group-buttons-footer" *ngIf="orderItems && orderItems.length > 0">
            <div class="total-order" *ngIf="totalOverFilteredValue === 0">
                <small>{{'Total' | i18n}}</small>
                {{orderCheckHistory.totalValue | money}}
            </div>

            <div class="total-order" *ngIf="totalOverFilteredValue > 0">
                <small>{{'TotalFiltered' | i18n}}</small>
                {{totalOverFilteredValue | money}}
            </div>

            <button *ngIf="configuration.CanCustomerCloseTable" type="button" class="btn-next" (click)="closeTab()">
                {{'AskForBill' | i18n }}
                <i class="fa-light fa-arrow-right"></i>
            </button>
        </div>
    </div>
</ng-container>