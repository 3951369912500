import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { MenuServiceConfigurationResponse } from '../../models/menu-service.model';
import { MenuService } from '../../services/menu-service.service';
import { UserService } from '../../services/user.service';
import { transitionAnimation } from '../../utils/animations';

@Component({
   selector: 'modal-welcome',
   templateUrl: './welcome.component.html',
   styleUrls: ['./welcome.component.scss'],
   animations: [transitionAnimation],
})
export class WelcomeComponent implements OnInit {
   public openModal = false;
   public dark = false;
   configuration: MenuServiceConfigurationResponse;

   @Output() afterCloseModalWelcome = new EventEmitter();

   @HostListener('window:popstate', ['$event'])
   onPopState(event: Event) {
      if (this.openModal) {
         this.close();
      }
   }

   constructor(private menuService: MenuService, private userService: UserService) {}

   ngOnInit(): void {}

   open(userInfo: any, configuration: MenuServiceConfigurationResponse) {
      this.configuration = configuration;

      this.openModal = true;
      this.menuService.overflowService(false);

      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
   }

   close() {
      this.openModal = false;
      this.menuService.overflowService(true);
      this.userService.setWelcomePageLastSeenOn();
      this.afterCloseModalWelcome.emit();
   }
}
