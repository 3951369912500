<ng-container *ngIf="openModal">
    <div class="modal modal-nav" @modal>
        <div class="nav-content">
            <i class="fa-light fa-xmark icon-close close-nav" (click)="close()"></i>

            <div *ngIf="userInfo?.Name && !isViewMode" class="user" (click)="handleOpenModalNameCustomer()">
                <i class="fa-duotone fa-circle-user"></i>
                {{userInfo.Name}}
            </div>

            <!--about-->
            <mat-accordion>
                <mat-expansion-panel hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div class="item-nav">
                                <i class="fa-duotone fa-store"></i>
                                {{'About' | i18n}}
                            </div>
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="group-week">
                        <div class="store-open" [ngClass]="configuration.StoreIsOpen ? 'open' : 'closed'">
                            {{(configuration.StoreIsOpen ? 'Open' : 'Closed') | i18n }}
                        </div>
                        <div class="title-section">
                            {{'OpeningHours' | i18n}}
                        </div>

                        <div class="week-day" *ngFor="let weekDay of configuration.WeekBusinessHours ">
                            <div class="week-day-name">
                                {{weekDay.WeekdayName | i18n}}
                            </div>
                            <div class="week-day-start-time">
                                {{weekDay.StartTime}}
                            </div>
                            <span>{{'At' | i18n}}</span>
                            <div class="week-day-end-time">
                                {{weekDay.EndTime}}
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div class="item-nav">
                                <i class="fa-duotone fa-gears"></i>
                                {{'Settings' | i18n}}
                            </div>
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="settings-content">
                        <div class="group-settings">
                            <div class="title-section">
                                {{'Theme' | i18n}}
                            </div>
                            {{isDarkMode}}
                            <mat-button-toggle-group [value]="selectedTheme" (change)="setDarkLightMode($event)">
                                <mat-button-toggle [value]="theme.Light"><i
                                        class="fa-solid fa-sun"></i></mat-button-toggle>
                                <mat-button-toggle [value]="theme.Dark"><i
                                        class="fa-solid fa-moon"></i></mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>

                        <hr />

                        <div class="group-settings">
                            <div class="title-section">
                                {{'FontSize' | i18n}}
                            </div>
                            <div class="group-buttons">
                                <button (click)="setFontSize('a-')" [disabled]="fontSize === 89.75">A-</button>
                                <button (click)="setFontSize('a')">A</button>
                                <button (click)="setFontSize('a+')" [disabled]="fontSize === 121.75">A+</button>
                            </div>
                        </div>
                    </div>

                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div class="footer-version">
            {{'MadeBy' | i18n}} KCMS 💙
            <span>v {{appVersion}}</span>
        </div>
    </div>
</ng-container>