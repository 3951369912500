import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HubServiceOrderCheckHistory } from '../models/hub.model';
import { Order } from '../models/order';

@Injectable({
   providedIn: 'root',
})
export class EventService {
   private orderChange = new Subject();
   private countdownChange = new Subject();
   private orderCheckHistoryChange = new Subject();
   public orderChanged$ = this.orderChange.asObservable();
   public onCountdownChange = this.countdownChange.asObservable();
   public orderCheckHistoryChanged$ = this.orderCheckHistoryChange.asObservable();

   publishOrderChange(order: Order) {
      this.orderChange.next(order);
   }

   publishCountdownEvent(countdown: number) {
      this.countdownChange.next(countdown);
   }

   publishOrderCheckHistoryChange(orderCheckHistory: HubServiceOrderCheckHistory) {
      this.orderCheckHistoryChange.next(orderCheckHistory);
   }
}
